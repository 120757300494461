import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { DarkModeProvider } from '@/hooks';
import { AuthProvider } from '@/contexts/AuthContext';
import '../styles/globals.css';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const App = ({ Component, emotionCache, pageProps }) => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  return (
    <AuthProvider>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <DarkModeProvider>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
            >
              <Component {...pageProps} />
            </Box>
          </DarkModeProvider>
        </ThemeProvider>
      </CacheProvider>
    </AuthProvider>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
  emotionCache: PropTypes.object,
};

App.defaultProps = {
  pageProps: {},
  emotionCache: muiCache,
};

export default App;
